
import Container from '@mui/material/Container'


const Help = () => {

	return (
		<Container>
			<h1>namecaster help</h1>
			<p>There are two ways to generate random names and occupations in namecaster:</p>
			<ul>
				<li>From the entire dataset.</li>
				<li>Based on specific criteria.</li>
			</ul>
			<p>To generate random names and occupations from the entire dataset, press the <code>random</code> button.</p>
			<p>To generate specific names and occupations based on specific criteria, press the <code>specific</code> button.</p>
			<p>The controls on the home page are used to set the criteria for generating names and occupations when 
				the <code>specific</code> button is pressed. They have no effect on the names and occupations generated when 
				the <code>random</code> button is pressed.</p>
			<p>When the controls are left at their defaults, pressing the <code>specific</code> button will yield the same
				results as pressing the <code>random</code> button, i.e. random names and occupations will be generated
				from the entire dataset.</p>
		</Container>
	)
}

export default Help