import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import DualSlider from './DualSlider'

import { useContext } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'


const NameSettings = props => {

	const formDisabled = useContext(FormDisabledContext)

	/*
		we have 3 settings properties in props.settings:
			* name first letter
			* name length (min,max)
			* name popularity (min,max)
	*/
	const setStartsWith = letter => {
		let {...settings} = props.settings
		settings.startsWith=letter
		return settings
	}

	const setLength = length => {
		let {...settings} = props.settings  
		settings.length = length
		return settings
	}

	const setPopularity = popularity => {
		let {...settings} = props.settings  
		settings.popularity = popularity
		return settings
	}

	return(
		<>
		<Divider sx={{mt:3,mb:3}}>{props.title}</Divider>
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<label>
				Start with:
			</label>
			<Select 
				disabled={formDisabled}
				defaultValue='any'
				value = {props.settings.startsWith}
				onChange={event => {
					props.onSettingsChange(setStartsWith(event.target.value))
				}}
				sx = {{
					width:'5rem'
				}}
			>
				<MenuItem value="any">any</MenuItem>
				<MenuItem value="A">A</MenuItem>
  				<MenuItem value="B">B</MenuItem>
  				<MenuItem value="C">C</MenuItem>
  				<MenuItem value="D">D</MenuItem>
				<MenuItem value="E">E</MenuItem>
  				<MenuItem value="F">F</MenuItem>
  				<MenuItem value="G">G</MenuItem>
  				<MenuItem value="H">H</MenuItem>
				<MenuItem value="I">I</MenuItem>
  				<MenuItem value="J">J</MenuItem>
  				<MenuItem value="K">K</MenuItem>
  				<MenuItem value="L">L</MenuItem>
				<MenuItem value="M">M</MenuItem>
  				<MenuItem value="N">N</MenuItem>
  				<MenuItem value="O">O</MenuItem>
  				<MenuItem value="P">P</MenuItem>
				<MenuItem value="Q">Q</MenuItem>
  				<MenuItem value="R">R</MenuItem>
  				<MenuItem value="S">S</MenuItem>
  				<MenuItem value="T">T</MenuItem>
				<MenuItem value="U">U</MenuItem>
  				<MenuItem value="V">V</MenuItem>
  				<MenuItem value="W">W</MenuItem>
  				<MenuItem value="X">X</MenuItem>
  				<MenuItem value="Y">Y</MenuItem>
  				<MenuItem value="Z">Z</MenuItem>
			</Select>
			<label>
				Length:
			</label>
			<DualSlider
				min = {props.settings.minLength}
				max = {props.settings.maxLength}
				value = {props.settings.length}
				onChange = {values => {
					props.onSettingsChange(setLength(values))
				}}
				// ariaLabel = 'Male first names length.'
				// labelText = ' chars'
			>	 
			</DualSlider>
			<label>
				Commonness:
			</label>
			<DualSlider
				min = {props.settings.minPopularity}
				max = {props.settings.maxPopularity}
				value = {props.settings.popularity}
				labelText = {{threshold:50,low:'common',high:'rare'}}
				onChange = {values => {
					props.onSettingsChange(setPopularity(values))
				}}
				// ariaLabel = 'Male first names popularity.'
				// labelText = ' chars'
			>	 
			</DualSlider>
		</Grid>
		</>
	)
}

export default NameSettings