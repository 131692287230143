
import Box from '@mui/system/Box'
import ResultsContent from './ResultsContent'

const Results = props => {


	return (
		<Box
			sx = {{
				minHeight: '21.2rem',
				maxHeight: '21.2rem',
				overflow: 'auto',
				
				border: '4px solid rgb(159, 86, 17)'
			}}
		>
			<ResultsContent
				results = {props.results}
			>
			</ResultsContent>
			{/* <pre>{JSON.stringify(props.settings,null,4)}</pre> */}
		</Box>
	)
}

export default Results  