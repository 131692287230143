import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'

import ResultsTable from './ResultsTable'

/*
	This component can render in very different and distinct ways
	based on the "results" parameter passed in.

	"results" can be one of the following:
		An array with person object entries
		An empty array
		The string "loading"
		An error object in one or more forms

	The "results" parameter is actually a state variable,
	and so when it changes, we change how we render as well.

	The possible renders we can do are:

		For a non-empty array of people, we display a grid
		component containing all the people and their jobs.

		For an empty array of people, we display an alert
		message stating that no people were returned from
		the server.

		For the string "loading", we display a loading
		spinner.

		For anything else, we assume it's an error object
		of some form, and so we display a general error
		alert message.
*/
const ResultsContent = ({results}) => {
	if (results === null)
		return (
			<></>	// initial launch state, show nothing
		)
	else if (Array.isArray(results)) { // we have results, which could also be an empty array
		
		if (results.length === 0) {
			return <Alert severity='info'>No names and/or jobs were found matching your requested criteria.</Alert>
		}
		else
			return (
				<ResultsTable
					people = {results}
				></ResultsTable>
			)
	}
	else if (results === 'loading') {
		return   <Box 
					sx={{justifyContent:'center', display: 'flex' }}
				>
					<CircularProgress
						sx={{marginTop: '9em'}}
					>
					</CircularProgress>
	  			</Box>
	}
	else if (results === 'invalid') {
		return <Alert severity='error'>Invalid data entered in the name generator form.</Alert>
	}
	else return (
		// an error occurred
		<Alert severity='error'>Error retrieving data from the server.</Alert>
	)
}

export default ResultsContent