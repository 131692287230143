import Avatar from '@mui/material/Avatar'
import logo from '../assets/images/icon-64.png'


const Header = props => {
	return (
		<header style={{marginTop: '-2rem',marginBottom:'1.5rem'}}>
			<Avatar
				alt='Stylized drawing of a human head in profile facing camera right.'
				src={logo}
				sx={{ width: 64, height: 64, borderRadius:32, margin: '0 auto'}}
			/>
			<h1>namecaster</h1>
			<h2>a random name and occupation generator</h2>
		</header>		
	)
}

export default Header