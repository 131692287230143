
const About = props => {
	return (
		<section>
			<h1>Namecaster &mdash;The Origin Story</h1>

			<p>As we programmers often do, I wrote Namecaster to scratch an itch. </p>

			<p>One of my hobbies is creative writing, and I occasionally need random character names and occupations to fill out a story. In the old days I used the phone book, opening it to a random page where I would scan for interesting first and last names. It sometimes took a few tries, but I always ended up with something useful. For occupations, I did the same with the yellow pages. When the web and cellphones became mainstream, I experimented with various online and mobile name generators, but none satisfied; I simply didn&#39;t like the generated output, which neither inspired nor delighted.</p>

			<p>Thankfully, the march of technology has provided us with a trove of raw online name and occupation data in the form of first and last names from the 2010 Census and a compendium of job titles from the Bureau of Labor Statistics. I downloaded the datasets, did some tweaking, and ended up with a SQLite3 database of names and occupations. This I called from a terminal window using a small Perl script I threw together in a couple of hours. And this is the setup I used for many, many years. </p>

			<p>Which brings us to today.</p>

			<p>While casting about for some React portfolio project ideas, I realized that Namecaster would be a perfect candidate for reimplementation as a proper web-based app with a proper UI and the ability to fine-tune the name and occupation generation criteria to a greater degree than the Perl script allowed. And so here we are: a React client calling a Fastify server through an NGINX reverse proxy, available to generate random names and jobs all day every day. </p>
			<p>And if the Internet connection ever goes down or the server crashes, I still have my tiny Perl script.</p>
		</section>
	)
}

export default About
