import { BrowserRouter as Router, NavLink, Link, Route, Routes } from 'react-router-dom'
import { useImmerReducer  } from 'use-immer'
import { useState } from 'react'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'

import AlertPanel from './AlertPanel'

import Home from './Home'
import Notes from './Notes'
import Stats from './Stats'
import Tech from './Tech'
import About from './About'
import Help from './Help'
import PageNotFound from './404'


/*
	This NavBar component does most of the app's heavy lifting, as it maintains
	the state of our main appllication states: all of the app's form inputs,
	and the server call results. Specifically:
		* The contents of the form used for setting generated name parameters, 
		  supplied to the SettingsForm component.
		* The contents of the server call results, supplied to the Results component.
	Note that the results window is also used to display errors:
		* Invalid form field values detected client-side.
		* Invalid POSTed form field values detected server-side.
		* Network/server errors.
		* Name generation errors (e.g. no names met requested criteria).
*/


const NavBar = props => {



	// immer reducer makes this function nice and small (and also safe)
	// the main draft (settings) object contains several sub-objects,
	// each of which is modified by various UI elements. The 'onChange'
	// handlers for these are passed a string representing the key for
	// the sub-object that was changed. That value is in action.type,
	// e.g. action.type === 'femaleNames' means the settings.femaleNames.
	// sub-object was passed into us, and has been changed.
	// Thus, we an use action.type to find what to set, and then set
	// it with action.settings, which is the relevant sub-object with
	// changes made by the user.
	const settingsReducer = (draft,action) => {
		draft[action.type] = action.settings
		return void draft
	}
	
	// all our state data is in one big place
	const [settings,dispatch] = useImmerReducer(settingsReducer,
		{
			general: {
				nameType: 'both',
				includeOccupations: true
			},
			maleNames: {
				startsWith: 'any',
				minLength:2,
				maxLength: 11,
				minPopularity: 1,
				maxPopularity: 100,
				length: [2,11],
				popularity: [1,100]
			},
			femaleNames: {
				startsWith: 'any',
				minLength:2,
				maxLength: 11,
				minPopularity: 1,
				maxPopularity: 100,
				length: [2,11],
				popularity: [1,100]
			},	
			lastNames: {
				startsWith: 'any',
				minLength:2,
				maxLength: 13,
				minPopularity: 1,
				maxPopularity: 100,
				length: [2,13],
				popularity: [1,100]
			},	
			occupations: {
				contains: ''	// 4-20 characters A-Z a-z
			}
		}
	)


	/*
		Will be one of the following:
			* an empty fragment (show nothing)
			* An empty results array (show error about no results found)
			* An array with results (see below)
			* The string "loading" indicating we are awaiting a server response
			* Anything else is treated as an error and an error message is displayed
		Our array of people results or an error.

		The Results array of people looks like:

			[
				{
					firstName: 'a name',
					lastName: 'a last name',
					occupation: 'a job'
				},
				etc...
			]

		Note that the array can be empty
	*/
	const [results, setResults] = useState(null)

	// callback passed to child components so that they can set state
	const setResultsData = data => {
		setResults(data)
	}

	return (
	<Router>
	<Box sx={{width: '100%'}}>
		<TabContext>
			<Box sx={{borderBottom: 1, marginBottom: '1rem',borderColor: 'divider' }}>
	<TabList
					centered 
					aria-label="main menu" 
					sx={{
						"& button": { fontSize: '1.1rem'}
					}}
				>

					<NavLink to={'/'} exact end
					>
						<Tab label="Home" />
					</NavLink>
					<NavLink to={'/notes'}
					>
						<Tab label="Notes" />
					</NavLink>
					<NavLink to={'/stats'}
					>
						<Tab label="Stats" />
					</NavLink>
					<NavLink to={'/tech'}
					>
						<Tab label="Tech" />
					</NavLink>
					<NavLink to={'/about'}
					>
						<Tab label="About"/>
					</NavLink>
					<NavLink to={'/help'}
					>
						<Tab label="Help"/>
					</NavLink>
				</TabList>
			</Box>
			<AlertPanel
				type = 'info'
				component = {<>Please see the <Link to='/notes'>notes</Link> page for important information about generated names and occupations.</>}
			/>
			<Routes>
				<Route path={'/notes'} element={<Notes />}/>
				<Route path={'/stats'} element={<Stats />}/>
				<Route path={'/tech'} element={<Tech />}/>
				<Route path={'/about'} element={<About />}/>
				<Route path={'/help'} element={<Help />}/>
				{/* below route handles both the index page and the 404 page, because they are the same */}
				<Route index path={'/'} element={<Home	
					settings = {settings}
					results = {results}  
					setResultsData = {setResultsData}
					onSettingsChange = {(data) => dispatch(data)}	// data is: {action:"type",settings:"settings object"}
				>
				</Home>}/>
				<Route path="*" element={<PageNotFound />}/> {/* our 404 not found handler */}
				</Routes>
		</TabContext>
	</Box>
	</Router>
	)
}

export default NavBar