
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid'

import { useContext } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'


const ButtonBar = ({onRandomClick,onSpecificClick}) => {

	const formDisabled = useContext(FormDisabledContext)

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx = {{
				marginTop:'1rem',
				marginBottom: '2rem'
			}}
		>
		<Box>
			<label>Generate:</label>
			<Button
				disabled={formDisabled}
				variant='contained'
				onClick = {onRandomClick}
			>
				random
			</Button
			>
			<Button
				disabled={formDisabled}
				variant='contained'
				onClick = {onSpecificClick}
			>
				specific
			</Button>
		</Box>
		</Grid>
	)
}

export default ButtonBar