import Box from '@mui/material/Box'


const PageNotFound = props => {

	return (
		<Box sx={{marginTop: '4em' }}>
			<h1>The requested page was not found.</h1>
		</Box>
	)
}

export default PageNotFound