import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'
import Divider from '@mui/material/Divider'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Switch from '@mui/material/Switch'

import { useContext } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'


const GeneralSettings = props => {

	const formDisabled = useContext(FormDisabledContext)

	const setName = type => {
		let {...settings} = props.settings
		settings.nameType=type
		return settings
	}

	const setIncludeOccupations = checked => {
		let {...settings} = props.settings
		settings.includeOccupations = checked
		return settings
	}

	return(
		<>
		<Divider>General Settings</Divider>
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx = {{
				marginTop:'1rem',
				marginBottom: '1rem'
			}}
		>
		<Box>
			<label
			>
				First name type:
			</label>
			<ToggleButtonGroup
				disabled={formDisabled}
				exclusive
				aria-label="first name type"
				value={props.settings.nameType}
				onChange = {e=>{props.onSettingsChange(setName(e.target.value))}}
				// color='primary'
				variant='contained'
				sx = {{backgroundColor:'lightblue'}}
			>
				<ToggleButton aria-label='female first names' value = 'female' variant='contained'>Female</ToggleButton>
				<ToggleButton aria-label='male first names' value = 'male' variant='contained'>Male</ToggleButton>
				<ToggleButton aria-label='female and male first names' value = 'both' variant='contained'>Both</ToggleButton>
			</ToggleButtonGroup>
			<label> Include occupations?	
			<Switch
				disabled={formDisabled}
				defaultChecked
				checked = {props.settings.includeOccupations}
				onChange = {e=>{props.onSettingsChange(setIncludeOccupations(e.target.checked))}}
			/>	
			</label>
		</Box>
		</Grid>
		</>
	)
}

export default GeneralSettings