// import {EOL} from 'os'
import { useState } from "react"
import { IconButton, Snackbar, SnackbarContent } from "@mui/material"
import CopyIcon from "@mui/icons-material/ContentCopy"

const CopyToClipboardButton = ({people}) => {
  const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);

		let names = ''

		// All names either have occupations or don't, so we can test the first element
		// to see if we need to do fancy name/occupation formatting or not.
		// If we don't have occupations, simply write out the first and last names.
		// If we DO have occupations, we first find the longest name, and then we
		// column-align the occupations six spaces past it on each line.

		// simply write out our names
		// \r\n below renders correctly on all operating systems.
		if (people[0].occupation === '') {
			people.map(person => (
				names += `${person.firstName} ${person.lastName}\r\n` 
			))	
		}
		else {
			// get longest name length (first name + ' ' + last name)
			let longestName = 0
			for (const person of people)
				longestName = Math.max(longestName,(person.firstName+person.lastName+1).length)
			longestName+=6 // now add six spaces breathing room between names and occupations

			// now rewalk the array writing out column-aligned names and occupations
			// \r\n below renders correctly on all operating systems.
			for (const person of people)
				names += `${(person.firstName+' '+person.lastName).padEnd(longestName)}${person.occupation}\r\n` 
		}

		// now copy the entire assembled list to the clipboard
		navigator.clipboard.writeText(names);
	}

  return (
    <>
      <IconButton 
	  		onClick={handleClick} 
			// color="primary"
			style = {{
				display: 'block',
				position: 'absolute',
				right: 0,
				zIndex: 2

			}}
			sx={{
				'&:hover':{background:'blue'},
				mt:'4px',mr:'4px',
				'&:active':{mt:'6px',mr:'2px'},
				background:'darkorange'
			}}
	>
        <CopyIcon
			sx={{
				ml: '5px',
				mr: '5px',
				mt: '4px',
			}}
		>
		</CopyIcon>
    </IconButton>
	<Snackbar
		anchorOrigin={{ vertical: "top", horizontal: "center" }}
		autoHideDuration={1500}
		onClose={() => setOpen(false)}
		open={open}
	>
		<SnackbarContent
			sx={{fontSize: '110%',backgroundColor: 'darkorange', border: '2px solid brown'}}
			message={'Copied to clipboard.'}
		/>
	</Snackbar>
    </>
  );
};

export default CopyToClipboardButton
