import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import './Stats.css'

// This array is used to populate all the image cards on the page.
const statsImages = [
	{
		src:'assets/images/fbyfl.png',
		alt:'Bar chart showing counts of female first names by first letter.',
		caption:'Female First Name Counts By First Letter'
	},
	{
		src:'assets/images/mbyfl.png',
		alt:'Bar chart showing counts of male first names by first letter.',
		caption:'Male First Name Counts By First Letter'
	},
	{
		src:'assets/images/lbyfl.png',
		alt:'Bar chart showing counts of last names by first letter.',
		caption:'Last Name Counts By First Letter'
	},
	{
		src:'assets/images/fbyl.png',
		alt:'Bar chart showing counts of female first names by length.',
		caption:'Female First Name Counts By Length'
	},
	{
		src:'assets/images/mbyl.png',
		alt:'Bar chart showing counts of male first names by length.',
		caption:'Male First Name Counts By Length'
	},
	{
		src:'assets/images/lbyl.png',
		alt:'Bar chart showing counts of last names by length.',
		caption:'Last Name Counts By Length'
	}
]

const Stats = props => {
	return (
		<>
		<h1
			style={{
				marginBottom: '1rem',
				marginTom: '2rem'
			}}
		>Dataset Statistics</h1>
		<TableContainer component={Paper} elevation={8} style={{backgroundColor:'bisque'}}>
			<h2
				style={{marginBottom: 0,marginTop:'0.5rem'}}
			>
				Name &amp; Occupation Counts
			</h2>
			<Table sx={{ minWidth: 650}} aria-label="female, male, and last name counts">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell style={{fontWeight:'bold'}} align="center">Female</TableCell>
						<TableCell style={{fontWeight:'bold'}} align="center">Male</TableCell>
						<TableCell style={{fontWeight:'bold'}} align="center">Last</TableCell>
						<TableCell style={{fontWeight:'bold'}} align="center">Occupations</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell style={{fontWeight:'bold'}} align="right">Count</TableCell>
						<TableCell align="center">4,275</TableCell>
						<TableCell align="center">1,219</TableCell>
						<TableCell align="center">88,799</TableCell>
						<TableCell align="center">4,575</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<figcaption>name and occupation counts</figcaption>
		</TableContainer>
		{/* now do our array of images, each of which is a figure with a bar chart and a caption */}
		{
			statsImages.map(image=>
				<Paper  elevation={8} style={{backgroundColor:'bisque'}}>
					<figure>
					<img
						class='stats-img'
						src={image.src}
						alt={image.alt}

					></img>
					<figcaption>{image.caption}</figcaption>
					</figure>
				</Paper>
			)
		}
		</>
	)
}

export default Stats
