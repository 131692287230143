
import Container from '@mui/material/Container'

import Results from './Results'
import SettingsForm from './SettingsForm'

const Home = props => {

	return (
		<Container>
			<Results
				results = {props.results}
			>
			</Results>
			<SettingsForm
				settings = {props.settings}
				setResults = {props.setResultsData} // this is called when the generator buttons are pressed
				onChange = {data => props.onSettingsChange(data)}
			>
			</SettingsForm>
		</Container>
	)
}

export default Home
