
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'

import { useContext } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'


function OccupationSettings(props) {

	const formDisabled = useContext(FormDisabledContext)

	const setOccupationText = text => {
		let {...settings} = props.settings  
		settings.contains = text
		return settings
	}

	return (
		<>
		<Divider>Occupation Settings</Divider>
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx = {{
				marginTop:'1rem'
			}}
		>
			<label>
				Contain:
			</label>
			<TextField 
				disabled={formDisabled}
				placeholder='4-20 letters'
				variant='outlined'
				sx = {{
					width: '15rem',
				}}
				onChange = {e => props.onSettingsChange(setOccupationText(e.target.value))}
				value = {props.settings.contains}
			>
			</TextField>
		</Grid>	
		</>
	)
}

export default OccupationSettings

