
import ButtonBar from './ButtonBar'
import GeneralSettings from './GeneralSettings'
import NameSettings from './NameSettings'
import OccupationSettings from './OccupationSettings'

import {useContext, useState } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'
import axios from 'axios'

import Ajv from 'ajv'
import schema from '../GeneratorSchema.mjs'
const ajv = new Ajv({allErrors: false}) // set to true if we want an array of all errors


// are we in dev or prod mode? This determines which server we run on
// and which port we listen on
const production = process.env.NODE_ENV === 'production' ? true : false
const DEV_PORT = 4242
const PROD_PORT = 4242
const DEV_RANDOM_ROUTE = `http://localhost:${DEV_PORT}/api/random`
const PROD_RANDOM_ROUTE = `https://namecaster.app/api/random`
const DEV_SPECIFIC_ROUTE = `http://localhost:${DEV_PORT}/api/specific`
const PROD_SPECIFIC_ROUTE = `https://namecaster.app/api/specific`

const RANDOM_ROUTE = production ? PROD_RANDOM_ROUTE : DEV_RANDOM_ROUTE
const SPECIFIC_ROUTE = production ? PROD_SPECIFIC_ROUTE : DEV_SPECIFIC_ROUTE

const validate = ajv.compile(schema)


const SettingsForm = ({settings,setResults,onChange}) => {

	// our actual form state -- disabled or enabled
	// this is applied to each lowest-level component on the form
	let [formIsDisabled,setFormIsDisabled] = useState(false)

	// Context so we can set child form elements without passing props.
	// We don't need to set it to anything because we pass the formIsDisabled
	// state that is declared above as our context value.
	useContext(FormDisabledContext)

	const handleRandomButtonClick = e => {
		e.preventDefault()
		e.stopPropagation()
		setDisabled(true)
		setResults('loading')

		axios.get(RANDOM_ROUTE)
		.then((response) => {
			setResults(response.data)
		}).catch(error => {
			// Regardless of error type, set our state to the error object.
			// The error parsing if blocks are left below in case we want
			// to eventually provide fine grained error info.
			setResults(error)

			/*
			// server sent a code not in the 200 range
			if (error.response) {
				// alert('error code:'+error.code+' status code: '+error.statusCode + 'error.response: response code not in 2xx range')
				//	if (error.response.status === ???)
				// 	(need to create this function)	handleBadDataPassedInError()
			}
			else if(error.request) {
				// request was made but no response arrived
				// alert ('error.request:'+error.request + `${error.message}`)
				// alert(JSON.stringify(error))
			}
			else {
				// some other error
				// /alert('some other error')
				// console.log(error.toJSON())
			}
			*/
		}).finally(() =>{
			setDisabled(false)
		}) 
	}

	const handleSpecificButtonClick = e => {
		e.preventDefault()
		e.stopPropagation()
		setDisabled(true)
		setResults('loading')

		// Perform validation on the form data.
		// The only field that can fail is the "occupation contains" field,
		// but we check EVERYTHING in case a wiley user tries to spoof
		// values in the developer console.
		const valid = validate(settings)
		if (!valid) {
			setResults('invalid')
			setDisabled(false)
			return
		}

		axios.post(SPECIFIC_ROUTE,settings, 
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response) => {
			setResults(response.data)
		}).catch(error => {
			setResults(error)
			/*
			// server sent a code not in the 200 range
			if (error.response) {
				alert('error:'+error.code+'error.response: response code not in 2xx range')
				//	if (error.response.status === ???)
				// 		handleBadDataPassedInError()
			}
			else if(error.request) {
				// request was made but no response arrived
				alert ('error.request:'+error.request + `${error.message}`)
			}
			else {
				// some other error
				alert('some other error')
				// console.log(error.toJSON())
			}
			*/
		}).finally(() =>{
			setDisabled(false)
		}) 
	}

	// toggle form state between enabled and disabled
	const setDisabled = (newState) => { setFormIsDisabled(newState) }	

	return (
		<form>
		<FormDisabledContext.Provider value={formIsDisabled}>
		<ButtonBar
			onRandomClick = {(e) => handleRandomButtonClick(e)}
			onSpecificClick = {(e)=> handleSpecificButtonClick(e)}
		>
		</ButtonBar>
		<GeneralSettings
			settings = {settings.general}
			onSettingsChange = {settings=>onChange({type:'general',settings:settings})}
		>
		</GeneralSettings>
		<NameSettings
			title = {'Male First Names'}
			settings = {settings.maleNames}
			onSettingsChange = {settings=>onChange({type:'maleNames',settings:settings})}
		>
		</NameSettings>
		<NameSettings
			title = {'Female First Names'}
			settings = {settings.femaleNames}
			onSettingsChange = {settings=>onChange({type:'femaleNames',settings:settings})}
		>
		</NameSettings>
		<NameSettings
			title = {'Last Names'}
			settings = {settings.lastNames}
			onSettingsChange = {settings=>onChange({type:'lastNames',settings:settings})}
		>
		</NameSettings>
		<OccupationSettings
			settings = {settings.occupations}
			onSettingsChange = {settings=>onChange({type:'occupations',settings:settings})}
		>
		</OccupationSettings>
		</FormDisabledContext.Provider>
		</form>
	)
}

export default SettingsForm