import Slider from '@mui/material/Slider'

import { useContext } from 'react'
import { FormDisabledContext } from './FormDisabledContext.js'

const DualSlider = props => {

	const formDisabled = useContext(FormDisabledContext)

	return(
		<Slider
			disabled={formDisabled}
			sx = {{
				width: '150px'
			}}
			getAriaLabel={() => props.ariaLabel}
			set = {1}
			min = {props.min}
			max = {props.max}
			value = {props.value}
			defaultValue={[props.min,props.max]}
			onChange={(e,values)=>props.onChange(values)}
			valueLabelDisplay='on'
			valueLabelFormat={value => !props.labelText ? value : value > props.labelText.threshold ? props.labelText.high : props.labelText.low}
			// aria-labelledby = 'ID of label element
		/>
	)
}

export default DualSlider