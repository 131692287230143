import { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/system/Box'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'

/*
	This MUI-derived alert component contains a close button which fades the box out.

	The component properties, all of which are required, are:
		type		the MUI alert type (warning,info,error,etc.)
		component	the component to display in the alert box
					example: <>This is an alert with a <Link to='/apage'>link</Link> to a page.</>
*/

const AlertPanel = props => {

	// used to close/open the alert panel
	// all Collapse/Alert code below taken from the MUI docs example
	const [open, setOpen] = useState(true);

	return (
		<Box sx={{marginLeft: '-4rem',marginRight:'-4rem'}}>
			<Collapse in={open}>
				<Alert
					severity={props.type}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{ mb: 2 }}
				>
					{/* This component/fragment contains the innerHTML to display. */}
					 {props.component}
					
				</Alert>
			</Collapse>		
		</Box>
	)
}

export default AlertPanel