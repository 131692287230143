
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/system/Box'
import CopyToClipboardButton from './CopyToClipboardButton'

const ResultsTable = props => {
	return(
		<Box sx={{position:'relative'}}>
			<TableContainer>
				<CopyToClipboardButton 
					aria-label="copy to clipboard"
					people = {props.people}
				>
				</CopyToClipboardButton>

				<Table sx={{minWidth: 400, position: 'relative' }} size="small" aria-label="A list of random names and occuptations.">
					<TableBody>
						{props.people.map((row) => (
							<TableRow
								sx={{'&:last-child td, &:last-child th': { border: 0 }}}
							>
								<TableCell sx={{fontFamily: 'monospace', afontSize: '105%', afontWeight: 'bold', width:'20em'}} align="left">{row.firstName} {row.lastName}</TableCell>
								<TableCell sx={{fontFamily: 'monospace', afontSize: '105%', afontWeight:'bold'}}  align="left">{row.occupation}</TableCell>
							</TableRow>
						))}					
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default ResultsTable
