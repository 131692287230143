
const minFemaleFirstName = 2
const minMaleFirstName = 2
const minLastName = 2
const maxFemaleFirstName = 11
const maxMaleFirstName = 11
const maxLastName = 13
const minPopularity = 1
const maxPopularity = 100


const femaleFirstNameRange = {
	type : 'integer',
	minimum : minFemaleFirstName,
	maximum : maxFemaleFirstName
}

const maleFirstNameRange = {
	type : 'integer',
	minimum : minMaleFirstName,
	maximum : maxMaleFirstName
}

const lastNameRange = {
	type : 'integer',
	minimum : minLastName,
	maximum : maxLastName
}

const popularityRange = {
	type : 'integer',
	minimum : minPopularity,
	maximum : maxPopularity
}


const schema = {
	type : 'object',
	properties : {
		'general' : {
			type: 'object',
			properties: {
				'nameType' : {'enum' : ['male','female','both']},
				'includeOccupations' : {'type': 'boolean'}
			},
			required: ['nameType','includeOccupations'],
			additionalProperties: false
		},
		'occupations' : {
			type: 'object',
			properties: {
				'contains': {
					'type': 'string',
					'pattern': '^$|^[A-Za-z]{4,20}$'
				}
			},
			required: ['contains'],
			additionalProperties: false
		},
		'femaleNames' : {
			type: 'object',
			properties: {
				'startsWith': {
					'type': 'string',
					'pattern':'^any$|^[A-Z]$'
				},
				'length' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [femaleFirstNameRange,femaleFirstNameRange]
				},
				'popularity' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [popularityRange,popularityRange]
				},
				'minLength'     : {'enum' : [minFemaleFirstName]},
				'maxLength'     : {'enum' : [maxFemaleFirstName]},
				'minPopularity' : {'enum' : [minPopularity]},
				'maxPopularity' : {'enum' : [maxPopularity]}
			},
			required: ['startsWith','length','popularity'],
			additionalProperties: false			
		},
		'maleNames' : {
			type: 'object',
			properties: {
				'startsWith': {
					'type': 'string',
					'pattern':'^any$|^[A-Z]$'
				},
				'length' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [maleFirstNameRange,maleFirstNameRange]
				},
				'popularity' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [popularityRange,popularityRange]
				},
				'minLength'     : {'enum' : [minMaleFirstName]},
				'maxLength'     : {'enum' : [maxMaleFirstName]},
				'minPopularity' : {'enum' : [minPopularity]},
				'maxPopularity' : {'enum' : [maxPopularity]}
			},
			required: ['startsWith','length','popularity'],
			additionalProperties: false			
		},
		'lastNames' : {
			type: 'object',
			properties: {
				'startsWith': {
					'type': 'string',
					'pattern':'^any$|^[A-Z]$'
				},
				'length' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [lastNameRange,lastNameRange]
				},
				'popularity' : {
					'type': 'array',
					minItems:2,
					maxItems:2,
					'items' : [popularityRange,popularityRange]
				},
				'minLength'     : {'enum' : [minLastName]},
				'maxLength'     : {'enum' : [maxLastName]},
				'minPopularity' : {'enum' : [minPopularity]},
				'maxPopularity' : {'enum' : [maxPopularity]}
			},
			required: ['startsWith','length','popularity'],
			additionalProperties: false			
		}
	},
	required : ['general','occupations','femaleNames','maleNames','lastNames'],
	additionalProperties: false	
}

export default schema
